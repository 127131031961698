import React from 'react';

function Three() {
  return (
    <div>
      Three
    </div>
  );
}

export default Three;
